import React from "react";
import Callout from "./callout";
export default function index({ bodyCopy }) {
  return (
    <Callout
      calloutTitle="Get the Conversation Started"
      buttonTitle="View Discussion Guide"
      buttonLink='https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/3e6e3e2b-90e9-4c7f-965a-b7cbf228b579/3e6e3e2b-90e9-4c7f-965a-b7cbf228b579_source__v.pdf'
      bodyCopy={bodyCopy}
      isTargetBlank
    >
      <p>
        Talking with your doctor about treatment options may be a little easier
        with the Doctor Discussion Guide.
      </p>
    </Callout>
  );
}
