import React from "react";
import { Link } from "gatsby"

export default function Callout({
  children,
  className,
  calloutTitle,
  buttonTitle,
  buttonLink,
  bodyCopy,
  isTargetBlank
}) {
  return (
    <div className={`callout ${className}`}>
      <div className="inner-container">
        <div className="callout-title" dangerouslySetInnerHTML={{ __html: calloutTitle }} />
        {
          bodyCopy ?
            <div className="callout-content" dangerouslySetInnerHTML={{ __html: bodyCopy }} />
            :
            <div className="callout-content">
              {children}
            </div>
        }
        <div className="callout-button-container">
          {isTargetBlank ? (
            <a
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
              className="callout-button"
              dangerouslySetInnerHTML={{ __html: buttonTitle }}
            />
          ) : (
            <Link
              to={buttonLink}
              className="callout-button"
              dangerouslySetInnerHTML={{ __html: buttonTitle }}
            />
          )}

        </div>
      </div>
    </div>
  );
}
