import React from 'react';
import Callout from './callout';
export default function index({ bodyCopy }) {
  return (
    <Callout
      calloutTitle="View the Patient Brochure"
      buttonTitle="Download Brochure"
      buttonLink='https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/013a795b-4679-488e-b082-9d609631dacc/013a795b-4679-488e-b082-9d609631dacc_source__v.pdf'
      isTargetBlank={true}
      bodyCopy={bodyCopy}
    >
      <p>Learn more about hypogonadism, treatment with AVEED<sup>&reg;</sup>, and how to save.</p>
    </Callout>
  )
}
